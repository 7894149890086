import { useProgress } from "@react-three/drei";
import React from "react";
import Background from "./components/Background";
import Contact from "./components/Contact";
import Guitar from "./components/Guitar";
import Loader from "./components/Loader";
import MixingTable from "./components/MixingTable";
import Portfolio from "./components/Portfolio";
import ScrollInicator from "./components/ScrollInicator";
import WebsiteTitle from "./components/WebsiteTitle";

export default function Main() {
  const progress = useProgress();
  React.useEffect(() => {
    console.log("progress", progress);
    if (progress.progress !== 100) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [progress]);
  return (
    <div>
      {progress.progress != 100 && <Loader />}
      {progress.progress == 100 && <ScrollInicator />}
      <Background />
      <WebsiteTitle />
      <Guitar />
      <MixingTable />
      <Portfolio />
      <Contact />
    </div>
  );
}
