import React from "react";
import useScrollPosition from "../hooks/scroll.hook";
import "../index.css";

export default function WebsiteTitle() {
  const scrollPosition = useScrollPosition();

  const [isTitleVisible, setIsTitleVisible] = React.useState(true);

  const [textPosition, setTextPosition] = React.useState(50);
  const [textOpacity, setTextOpacity] = React.useState(0);

  const [musicianPosition, setMusicianPosition] = React.useState(0);
  const [musicianOpacity, setMusicianOpacity] = React.useState(0);
  const [musicianVisible, setMusicianVisible] = React.useState(true);

  const [engineerPosition, setEngineerPosition] = React.useState(0);
  const [engineerOpacity, setEngineerOpacity] = React.useState(0);
  const [engineerVisible, setEngineerVisible] = React.useState(false);

  React.useEffect(() => {
    if (scrollPosition === 0) {
      setTextOpacity(0);
    }

    if (scrollPosition > 0 && scrollPosition <= 400) {
      setTextOpacity(scrollPosition * 0.0025);
      setTextPosition(scrollPosition * 0.125);
    }

    // Musician text
    if (scrollPosition < 500) {
      setMusicianVisible(true);
      setMusicianOpacity(0);
    }
    if (scrollPosition > 500 && scrollPosition <= 700) {
      setMusicianOpacity((scrollPosition - 500) * 0.005);
      setMusicianPosition((scrollPosition - 500) * 0.5);
    }

    if (scrollPosition > 1000 && scrollPosition <= 1200) {
      setMusicianOpacity(1 - (scrollPosition - 1000) * 0.005);
      setMusicianPosition(100 + (scrollPosition - 1000) * 0.5);
    }

    if (scrollPosition > 1200) {
      setMusicianOpacity(0);
      setMusicianVisible(false);
      setEngineerVisible(true);
    }

    if (scrollPosition < 1200) {
      setEngineerVisible(false);
      setMusicianVisible(true);
    }

    if (scrollPosition > 1200 && scrollPosition <= 1400) {
      // Engineer text enter
      setEngineerOpacity((scrollPosition - 1200) * 0.005);
      setEngineerPosition(100 - (scrollPosition - 1200) * 0.5);
    }

    if (scrollPosition > 1700 && scrollPosition <= 1900) {
      // Engineer text exit
      setEngineerOpacity(1 - (scrollPosition - 1700) * 0.005);
      setEngineerPosition(0 - (scrollPosition - 1700) * 0.5);
    }

    if (scrollPosition > 1900) {
      setEngineerOpacity(0);
    }

    if (scrollPosition > 2000) {
      // Fade out main text
      setTextOpacity(1 - (scrollPosition - 2000) * 0.0025);
      setTextPosition(50 + (scrollPosition - 2000) * 0.125);
    }

    if (scrollPosition > 2500) {
      setIsTitleVisible(false);
    } else {
      setIsTitleVisible(true);
    }

    // if (scrollPosition > 600 && scrollPosition <= 1000) {
    //   setTextPosition(scrollPosition * 0.125);
    //   setTextOpacity(1 - (scrollPosition - 600) * 0.0025);
    // }

    // if (scrollPosition > 1000 || scrollPosition === 0) {
    //   setTextOpacity(0);
    // }
  }, [scrollPosition]);
  return (
    <div>
      {isTitleVisible && (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            zIndex: 2,
          }}
        >
          <h1
            id="websiteTitle"
            style={{
              transform: `translateY(${textPosition}px)`,
              opacity: textOpacity,
            }}
          >
            Tom <span id="websiteTitleLastname">Larboni</span>
          </h1>
          <h2
            id="websiteTitleSpeciality"
            style={{
              display: musicianVisible ? "block" : "none",
              transform: `translateX(${musicianPosition - 100}px)`,
              opacity: musicianOpacity,
            }}
          >
            Musician
          </h2>
          <h2
            id="websiteTitleSpeciality"
            style={{
              display: engineerVisible ? "block" : "none",
              transform: `translateX(${engineerPosition}px)`,
              opacity: engineerOpacity,
            }}
          >
            Sound engineer
          </h2>
        </div>
      )}
    </div>
  );
}
