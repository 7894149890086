import React from "react";
import {
  Box,
  CameraShake,
  Environment,
  OrbitControls,
  useProgress,
} from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import useScrollPosition from "../hooks/scroll.hook";
import { isMobile } from "react-device-detect";
import { MixingTableModel } from "../assets/models/MixingTableModel";

export default function MixingTable() {
  const INITIAL_MIXING_TABLE_ROTATION = 20;
  const [rotY, setRotY] = React.useState(INITIAL_MIXING_TABLE_ROTATION);
  const [mixingTableOpacity, setMixingTableOpacity] = React.useState(0);

  const scrollPosition = useScrollPosition();

  React.useEffect(() => {
    if (scrollPosition < 1200) {
      setMixingTableOpacity(0);
    }

    if (scrollPosition > 1200) {
      // Fade in the mixing table
      setMixingTableOpacity((scrollPosition - 1200) * 0.005);
    }

    if (scrollPosition > 1700 && scrollPosition <= 1900) {
      // Fade out the mixing table
      setMixingTableOpacity(1 - (scrollPosition - 1700) * 0.005);
    }

    if (scrollPosition > 1200 && scrollPosition <= 1900) {
      // Rotate mixing table
      setRotY(INITIAL_MIXING_TABLE_ROTATION - (scrollPosition - 1200) * 0.05);
    }

    if (scrollPosition > 1900) {
      setMixingTableOpacity(0);
    }
  }, [scrollPosition]);

  return (
    <div>
      <Canvas
        style={{
          //background: 'rgba(255, 255, 255, 0.1)',
          width: isMobile ? "100vw" : "30vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          right: 0,
          opacity: mixingTableOpacity,
          zIndex: 1,
        }}
      >
        <directionalLight position={[10, 10, 5]} intensity={1} />
        <MixingTableModel
          posX={-3}
          posY={-7}
          posZ={-17}
          rotX={20}
          rotY={rotY}
          rotZ={0}
        />
        {/* <Environment preset="sunset" background /> */}
      </Canvas>
    </div>
  );
}
