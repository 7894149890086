import React, { useRef } from "react";
import { tracks } from "../assets/tracks";
import { isMobile } from "react-device-detect";
import ProgressBar from "@ramonak/react-progress-bar";
import { formatTime } from "../assets/helpers/time.helpers";

export default function Portfolio() {
  const [selectedTrack, setSelectedTrack] = React.useState(tracks[0]);
  const [audio, setAudio] = React.useState(new Audio(selectedTrack.url));
  const [playing, setPlaying] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(0);

  const togglePlayBack = () => setPlaying(!playing);

  React.useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing]);

  React.useEffect(() => {
    setCurrentTime(0);
    audio.addEventListener("ended", () => setPlaying(false));
    audio.addEventListener("timeupdate", (e: any) => {
      setCurrentTime(Math.round(audio.currentTime));
    });
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
      audio.removeEventListener("timeupdate", (e: any) => {
        console.log("progress deleted...");
      });
    };
  }, [audio]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(/images/background.jpg)",
        backgroundSize: "cover",
        zIndex: 999,
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          paddingTop: "100px",
          paddingBottom: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2
          style={{
            color: "white",
            fontFamily: "Noto Sans",
            textTransform: "uppercase",
            fontWeight: 900,
            fontSize: "2.5rem",
          }}
        >
          Portfolio
        </h2>
        <div
          style={{
            width: "50vw",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <img
            style={{
              width: "10rem",
              height: "10rem",
              borderRadius: "10px",
            }}
            src={selectedTrack.art}
            alt={`${selectedTrack.id}-${selectedTrack.title}-${selectedTrack.artist}`}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: isMobile ? "center" : "flex-start",
            }}
          >
            <p
              style={{
                color: "white",
                fontSize: "2rem",
                fontFamily: "Noto Sans",
                fontWeight: 700,
                margin: 0,
              }}
            >
              {selectedTrack.title}
            </p>
            <p
              style={{
                color: "white",
                fontSize: "1.25rem",
                fontFamily: "Noto Sans",
                fontWeight: 400,
                margin: 0,
              }}
            >
              {selectedTrack.artist}
            </p>
            <p
              style={{
                color: "white",
                fontSize: ".75rem",
                fontFamily: "Noto Sans",
                fontWeight: 200,
                margin: 0,
                fontStyle: "italic",
              }}
            >
              {selectedTrack.album}
            </p>
          </div>
        </div>
        <div
          style={{
            width: isMobile ? "100vw" : "50vw",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "2rem",
          }}
        >
          <p
            style={{
              color: "white",
              fontSize: ".75rem",
              fontFamily: "Noto Sans",
              fontWeight: 400,
              margin: 0,
            }}
          >
            {formatTime(currentTime)}
          </p>
          <ProgressBar
            completed={currentTime}
            width={isMobile ? "60vw" : "30vw"}
            height={"5px"}
            customLabel={" "}
            bgColor={"#fff"}
            baseBgColor={"#333"}
            maxCompleted={selectedTrack.length}
          />
          <p
            style={{
              color: "white",
              fontSize: ".75rem",
              fontFamily: "Noto Sans",
              fontWeight: 400,
              margin: 0,
            }}
          >
            {formatTime(selectedTrack.length)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: isMobile ? "50vw" : "30vw",
            marginTop: "1rem",
          }}
        >
          <button
            style={{
              zIndex: 999,
              border: "none",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              console.log("previous");
            }}
          >
            <img
              src={"/images/arrow.png"}
              alt="previous-btn"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
              }}
            />
          </button>
          <button
            style={{
              zIndex: 999,
              border: "2px solid white",
              borderRadius: "3.5rem",
              width: "3.5rem",
              height: "3.5rem",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              togglePlayBack();
            }}
          >
            <img
              src={!playing ? "/images/play.png" : "/images/pause.png"}
              alt="play-pause-btn"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
              }}
            />
          </button>
          <button
            style={{
              zIndex: 999,
              border: "none",
              backgroundColor: "transparent",
            }}
            onClick={() => {
              console.log("next");
            }}
          >
            <img
              src={"/images/arrow.png"}
              alt="next-btn"
              style={{
                width: "1.5rem",
                height: "1.5rem",
                cursor: "pointer",
                transform: "rotate(180deg)",
              }}
            />
          </button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1rem",
            width: isMobile ? "95vw" : "50vw",
          }}
        >
          {tracks.map((track) => (
            <div
              key={track.id}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: isMobile ? "95vw" : "50vw",
                marginTop: "-.5rem",
                zIndex: 999,
              }}
            >
              <p
                style={{
                  color: "white",
                  width: isMobile ? "10vw" : "5vw",
                  fontSize: ".9rem",
                  fontFamily: "Noto Sans",
                  textAlign: "center",
                }}
              >
                {track.id}
              </p>
              <p
                style={{
                  color: "white",
                  width: isMobile ? "70vw" : "35vw",
                  fontSize: ".9rem",
                  fontFamily: "Noto Sans",
                }}
              >
                {`${track.title} — ${track.artist} (${track.album})`}
              </p>
              <p
                style={{
                  color: "white",
                  width: isMobile ? "11vw" : "5vw",
                  fontSize: ".9rem",
                  fontFamily: "Noto Sans",
                  textAlign: "center",
                }}
              >
                {formatTime(track.length)}
              </p>
              <button
                style={{
                  border: "none",
                  width: isMobile ? "10vw" : "5vw",
                  textAlign: "center",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  setSelectedTrack(track);
                  audio.pause();
                  setPlaying(false);
                  setAudio(new Audio(track.url));
                  setCurrentTime(audio.currentTime);
                }}
              >
                <img
                  src={"/images/play.png"}
                  alt="play-btn"
                  style={{
                    width: isMobile ? "4vw" : "1.5vw",
                    height: isMobile ? "4vw" : "1.5vw",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
