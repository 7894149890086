import { useFrame } from "@react-three/fiber";
import React from "react";

export default function Background() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background:
          "radial-gradient(circle, rgb(30, 30, 30) 0%, rgba(0, 0, 0, 1) 100%",
        zIndex: -1,
      }}
    ></div>
  );
}
