import Lottie from "lottie-react";
import { isMobile } from "react-device-detect";

import loaderAnimation from "../assets/animations/loader.json";
export default function Loader() {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <Lottie
        animationData={loaderAnimation}
        loop={true}
        style={{
          width: isMobile ? "50px" : "150px",
          height: isMobile ? "50px" : "150px",
        }}
      />
    </div>
  );
}
