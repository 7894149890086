/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import { useFrame, useLoader } from "@react-three/fiber";
import { Suspense, useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

interface Props {
  posX: number;
  posY: number;
  posZ: number;
  rotX: number;
  rotY: number;
  rotZ: number;
}

export function MixingTableModel(props: Props) {
  const gltf = useLoader(GLTFLoader, "/model/mixing_table_draco.glb");
  const refMesh = useRef(null);

  /**
   * Draco loader
   */
  // const dracoLoader = new DRACOLoader();
  // dracoLoader.setDecoderPath("/draco"); // use a full url path

  // const gltfDraco = new GLTFLoader();
  // gltfDraco.setDRACOLoader(dracoLoader);

  // const glbPath = "/model/testDraco.glb";
  // gltfDraco.load(glbPath, function (gltf) {
  //   console.log(gltf);
  // });

  const { posX, posY, posZ, rotX, rotY, rotZ } = props;

  const degToRad = (deg: number) => {
    return deg * (Math.PI / 180);
  };
  return (
    <mesh
      position={[posX, posY, posZ]}
      rotation={[degToRad(rotX), degToRad(rotY), degToRad(rotZ)]}
    >
      <primitive object={gltf.scene} scale={0.1} />
    </mesh>
  );
}
