import React from "react";
import {
  Box,
  CameraShake,
  Environment,
  OrbitControls,
  useProgress,
} from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import { GuitarModel } from "../assets/models/GuitarModel";
import useScrollPosition from "../hooks/scroll.hook";
import { isMobile } from "react-device-detect";

export default function Guitar() {
  const INITIAL_GUITAR_ROTATION = 155;
  const { progress } = useProgress();
  const [rotZ, setRotZ] = React.useState(INITIAL_GUITAR_ROTATION);
  const [guitarOpacity, setGuitarOpacity] = React.useState(0);

  const scrollPosition = useScrollPosition();

  React.useEffect(() => {
    //console.log(scrollPosition);
    if (scrollPosition === 0) {
      setGuitarOpacity(0);
    }
    if (scrollPosition > 500 && scrollPosition <= 700) {
      // Fade-in guitar
      setGuitarOpacity((scrollPosition - 500) * 0.005);
    }

    if (scrollPosition > 1000 && scrollPosition <= 1200) {
      // Fade out guitar
      setGuitarOpacity(1 - (scrollPosition - 1000) * 0.005);
    }

    if (scrollPosition > 500 && scrollPosition <= 1200) {
      // Rotate guitar
      setRotZ(INITIAL_GUITAR_ROTATION + (scrollPosition - 500) * 0.1);
    }

    if (scrollPosition > 1200) {
      setGuitarOpacity(0);
    }
  }, [scrollPosition]);

  return (
    <div
      style={{
        height: "3300px",
      }}
    >
      <Canvas
        style={{
          //background: 'rgba(255, 255, 255, 0.1)',
          width: isMobile ? "100vw" : "30vw",
          height: "100vh",
          position: "fixed",
          top: 0,
          left: 0,
          opacity: guitarOpacity,
          zIndex: 1,
        }}
      >
        <directionalLight position={[10, 10, 5]} intensity={4} />
        <GuitarModel
          posX={0}
          posY={-1}
          posZ={0}
          rotX={-90}
          rotY={-5}
          rotZ={rotZ}
        />
        {/* <Environment preset="sunset" background /> */}
      </Canvas>
    </div>
  );
}
