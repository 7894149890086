/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/
import * as React from "react";
import { useLoader } from "@react-three/fiber";
import { useRef } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

interface Props {
  posX: number;
  posY: number;
  posZ: number;
  rotX: number;
  rotY: number;
  rotZ: number;
}

export function GuitarModel(props: Props) {
  // const [gltfScene, setGltfScene] = React.useState<any>(null);
  // const loader = new GLTFLoader();
  // const dracoLoader = new DRACOLoader();
  // dracoLoader.setDecoderPath("/draco");
  // loader.setDRACOLoader(dracoLoader);
  // loader.load(
  //   "/model/guitar_v4.gltf",
  //   (d) => {
  //     setGltfScene(d.scene);
  //     //this.scene.add(d.scene);
  //   },
  //   undefined,
  //   (e) => {
  //     console.error(e);
  //   }
  // );

  const gltf = useLoader(GLTFLoader, "/model/guitar_v4.glb");

  const { posX, posY, posZ, rotX, rotY, rotZ } = props;

  const degToRad = (deg: number) => {
    return deg * (Math.PI / 180);
  };
  return (
    <mesh
      position={[posX, posY, posZ]}
      rotation={[degToRad(rotX), degToRad(rotY), degToRad(rotZ)]}
    >
      <primitive object={gltf.scene} scale={0.5} />
    </mesh>
  );
}
