import React from "react";
import { emails } from "../services/email.service";
import { isMobile } from "react-device-detect";
import "../index.css";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {
  const [subject, setSubject] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [name, setName] = React.useState("");

  const handleSendEmail = async () => {
    // Test if all fields are filled
    if (subject === "" || message === "" || email === "" || name === "") {
      toast.error("Please fill all fields");
      return;
    } else {
      const sendEmail = await emails.send({
        subject,
        message,
        reply_email: email,
        sender: name,
      });

      // @ts-ignore
      if (sendEmail.status === 200) {
        toast.success("Email sent successfully!");
        setSubject("");
        setMessage("");
        setEmail("");
        setName("");
      } else {
        toast.error("Error sending email, please try again.");
      }

      console.log(sendEmail);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(/images/contact.jpg)",
        backgroundSize: "cover",
        zIndex: 999,
      }}
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        style={{ fontFamily: "Noto Sans" }}
        theme="dark"
        transition={Slide}
      />
      <div
        style={{
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          paddingTop: "100px",
          paddingBottom: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 999,
        }}
      >
        <h2
          style={{
            color: "white",
            fontFamily: "Noto Sans",
            textTransform: "uppercase",
            fontWeight: 900,
            fontSize: "2.5rem",
          }}
        >
          Contact me
        </h2>
        <input
          type="text"
          name="name"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Enter your full name..."
          style={{
            fontSize: "1rem",
            width: isMobile ? "90vw" : "50vw",
            height: "40px",
            paddingLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontFamily: "Noto Sans",
            border: "3px solid white",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email..."
          style={{
            fontSize: "1rem",
            width: isMobile ? "90vw" : "50vw",
            height: "40px",
            paddingLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontFamily: "Noto Sans",
            border: "3px solid white",
            borderRadius: "5px",
          }}
        />
        <input
          type="text"
          name="subject"
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Subject of your request..."
          style={{
            fontSize: "1rem",
            width: isMobile ? "90vw" : "50vw",
            height: "40px",
            paddingLeft: "10px",
            marginRight: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontFamily: "Noto Sans",
            border: "3px solid white",
            borderRadius: "5px",
          }}
        />
        <textarea
          name="message"
          id="message"
          cols={30}
          rows={10}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message..."
          value={message}
          style={{
            fontSize: "1rem",
            width: isMobile ? "90vw" : "50vw",
            height: "150px",
            padding: "10px",
            marginTop: "10px",
            marginBottom: "10px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            fontFamily: "Noto Sans",
            border: "3px solid white",
            borderRadius: "5px",
            resize: "none",
          }}
        />

        <button
          style={{
            fontSize: "1rem",
            width: isMobile ? "80vw" : "20vw",
            height: "60px",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            color: "white",
            border: "3px solid white",
            marginTop: "30px",
            borderRadius: "5px",
            fontFamily: "Noto Sans",
            fontWeight: 900,
            textTransform: "uppercase",
            cursor: "pointer",
          }}
          onClick={() => handleSendEmail()}
        >
          Send !
        </button>
      </div>
    </div>
  );
}

/**
 *
 */
