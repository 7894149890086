export const tracks = [
  {
    id: 1,
    title: "Track 1",
    artist: "Artist 1",
    album: "Album 1",
    url: "/audios/music1.mp3",
    art: "/images/albums/cover1.jpeg",
    length: 101,
  },
  {
    id: 2,
    title: "Great Track",
    artist: "Divide & Collide",
    album: "FORC",
    url: "/audios/music2.mp3",
    art: "/images/albums/cover2.jpeg",
    length: 125,
  },
];
