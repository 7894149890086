import emailjs from "@emailjs/browser";

const sendEmail = async (params: {
  subject: string;
  sender: string;
  reply_email: string;
  message: string;
}) => {
  try {
    const sentEmail = await emailjs.send(
      "service_i54whjt",
      "template_aspmyvg",
      params,
      "EtMMGGpkj561KTotN"
    );
    return sentEmail;
  } catch (error) {
    return error;
  }
};

export const emails = {
  send: sendEmail,
};
