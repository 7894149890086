import React from "react";
import Lottie from "lottie-react";

import scrollAnimation from "../assets/animations/scroll.json";
import useScrollPosition from "../hooks/scroll.hook";

export default function ScrollInicator() {
  const scrollPosition = useScrollPosition();

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
        opacity: scrollPosition !== 0 ? 0 : 1,
      }}
    >
      <Lottie
        animationData={scrollAnimation}
        loop={true}
        width={500}
        height={500}
      />
    </div>
  );
}
